<template>
  <div v-if="details.catalogModelInfoVOList&&details.catalogModelInfoVOList.length>0" class="wrapper flex fd-c ai-c">
      <div class="banner">
          <van-swipe class="banner" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in banner" :key="index">
                <img class="banner" :src="item" alt="">
            </van-swipe-item>
          </van-swipe>
          
      </div>
      <div class="time-box flex ai-c jc-c">
          <div class="txt">
              限时抢券
          </div>
          <!-- <div class="time">{{timeObj.endDay}}</div>
          <div class="txt" style="margin:0">
              天
          </div> -->
          <div class="time">{{timeObj.endHours||'00'}}</div>
          <div class="colon">:</div>
          <div class="time">{{timeObj.endMin||'00'}}</div>
          <div class="colon">:</div>
          <div class="time">{{timeObj.endSen||'00'}}</div>
          <div class="txt">
              后结束
          </div>
      </div>
      <template v-if="list1.length>0">
        <div class="title-img" :style="{'background-image': 'url('+(list1.length>0&&list1[0].cover)+')'}"></div>
        <div class="list-box1">
            <div class="list flex fd-c ai-c">
                <div class="item flex ai-c" v-for="(item, index) in list1" :key="index">
                    <img class="goodsPicture" :src="item.couponCover" alt="">
                    <div class="item-r">
                        <div class="flex ai-c">
                            <div class="i">满{{item.reduceCondition}}可用</div><div class="name line">{{item.couponName}}</div>
                        </div>
                        <div class="money">{{item.reduceAmount}}</div>
                        <!-- <div class="time">使用时间：10.10-10.25</div> -->
                    </div>
                    <div v-show="item.receiveStatus==0&&item.stockType==1" class="btn1 flex ai-c jc-c"  @click="doReceive(item)">立即领取<div class="icon"></div></div>
                    <div v-show="item.receiveStatus==1" class="btn2 flex ai-c jc-c" @click="$router.push('/my/coupons')">去使用<div class="icon"></div></div>
                    <div v-show="item.stockType==0" class="btn3 flex ai-c jc-c">已抢完<div class="icon"></div></div>
                </div>
            </div>
        </div>
      </template>
      <template v-if="list2.length>0">
        <div class="title-img" :style="{'background-image': 'url('+(list2.length>0&&list2[0].cover)+')'}"></div>
        <div class="list-box2 flex fd-c ai-c jc-fs">
            <van-swipe class="my-swipe" indicator-color="white">
                    <van-swipe-item class="list flex fw-w" v-for="(item, index) in Math.ceil(list2.length/6)" :key="index">
                        <template v-for="(im, ix) in list2">
                            <div v-if="ix>=index*6&&ix<(index+1)*6" class="item flex fd-c ai-c" @click="$router.push('/my/coupons')" :key="index+'-'+ix">
                                <img class="goodsPicture" :src="im.couponCover" alt="">
                                <div class="coupon flex fd-c ai-c jc-c">
                                    <div class="t1">¥{{im.reduceAmount}}</div>
                                    <div class="t2">满{{im.reduceCondition}}可用</div>
                                </div>
                            </div>
                        </template>
                    </van-swipe-item>
                
            </van-swipe>
            
            
        </div>
        <!-- <div class="timer flex ai-c jc-c">
                本轮限时10.13~10.20  剩余 <div class="font">06天15:12:55</div> 
        </div> -->
        <div class="invitation-btn" @click="shareClick"></div>
        <!-- <div class="poster-btn"></div> -->
      </template>
      <template v-if="list3.length>0">
        <div class="title-img" :style="{'background-image': 'url('+(list3.length>0&&list3[0].cover)+')'}" style="margin-top:.9rem;"></div>
        <div class="list-box3">
            <div class="list flex fd-c ai-c">
                <div class="item flex ai-c" v-for="(item, index) in list3" :key="index">
                    <img class="goodsPicture" :src="item.couponCover" alt="">
                    <div class="item-r">
                        <div class="name line">{{item.couponName}}</div>
                        <div class="money">¥<font>{{item.reduceAmount}}</font>满{{item.reduceCondition}}可用</div>
                    </div>
                    <div v-show="item.receiveStatus==0&&item.stockType==1" @click="doReceive(item)" class="btn1">领取</div>
                    <div v-show="item.receiveStatus==1" @click="$router.push('/my/coupons')" class="btn2">去使用</div>
                    <div v-show="item.stockType==0" class="btn3">已抢完</div>
                    <img v-show="item.receiveStatus==1" class="received" src="/img/received.png" alt="">
                </div>
            </div>
        </div>
      </template>
      <v-popup ref="popup" :list="list2" />
  </div>
</template>

<script>
import { getCatalogs, doReceive, doShare } from '../../api'
import popup from './popup' 
export default {
  components:{
      'v-popup': popup
  },
  props:{},
  data(){
    return {
        banner: [],
        list1: [],
        list2: [],
        list3: [],
        timer: null,
        lefttime: 0,
        timeObj: {
            endDay: '00',
            endHours: '00',
            endMin: '00',
            endSen: '00',
        },
        activeStatus: '',
        details: {}
    }
  },
  watch:{
      
  },
  computed:{},
  methods:{
      getCatalogs() {
          getCatalogs({}).then(res=>{
              var list = res.data.data.catalogModelInfoVOList
              this.banner = res.data.data.cover?res.data.data.cover.split(','):[]
              for(var item of list) {
                  if (item.mid==1) {
                    this.list1.push(item)   
                  }
                  if (item.mid==2) {
                    this.list2.push(item)   
                  }
                  if (item.mid==3) {
                    this.list3.push(item)   
                  }
              }
              this.details = res.data.data
              this.countdown(res.data.data.timeStamp)
          })
      },
      countdown (time) {
        // let endDate = new Date(endTime.replace(/-/g, "/")).getTime() / 1000
        let lefttime = time
        this.timer = setInterval(() => {
            // let date = new Date();  
            // let startDate = date.getTime() / 1000
            // let lefttime = ((endDate - startDate)<0)?0:(endDate - startDate);  
            lefttime--
            let endDay = parseInt(lefttime / (24 * 60 * 60))
            let endHours = parseInt(lefttime / (60 * 60) % 24)
            let endMin = parseInt(lefttime / 60 % 60)
            let endSen = parseInt(lefttime % 60)
            if (endHours < 10) {
                endHours = '0' + endHours
            }
            if (endMin < 10) {
                endMin = '0' + endMin
            }
            if (endSen < 10) {
                endSen = '0' + endSen
            }
            
            this.spanText = endDay + '天' + endHours + '时' + endMin + '分' + endSen + '秒后开抢'
            this.timeObj = {
                endDay: endDay,
                endHours: endHours,
                endMin: endMin,
                endSen: endSen,
            }
            // if (endDay <= 0) {
            //     this.spanText = endHours + '时' + endMin + '分' + endSen + '秒后开抢'
            // }
            // if (endHours <= 0 && endDay <= 0) {
            //     this.spanText = endMin + '分' + endSen + '秒后开抢'
            // }
            if (endSen <= 0 && endMin <= 0 && endHours <= 0 && endDay <= 0) {
                if (this.activeStatus == 1) {
                    clearInterval(this.timer)
                    this.activeStatus = 2
                }
                if (this.activeStatus == 2 || this.activeStatus == 3) {
                    clearInterval(this.timer)
                }
                if (this.activeStatus == '') {
                    clearInterval(this.timer)
                    this.activeStatus = 2
                }
            }
        }, 1000);
        
    },
    //领券
    doReceive(item){
        console.log(item);
        this.$toast.loading({
            duration: 0,
            forbidClick: true
        })
        doReceive({
            code: item.couponCode
        }).then(res=>{
            item.receiveStatus = 1
            this.$toast.success('领取成功')
        }).catch(res=>{
            this.$toast.clear()
            if (res.data.code == 400) {
                this.$dialog.alert({
                    title: '提示',
                    message: res.data.message
                })
            }
        })
    },
    //点击邀请好友
    shareClick () {
        var agent = navigator.userAgent.toLowerCase()
        if (/micromessenger/.test(agent)) {
            this.$store.state.shareTs = true
        }
        console.log(this.details.sharerId);
        console.log(this.details.rid);
        console.log(this.list2[0].mid);
        this.$ittShare({
            title: this.details.title,
            desc: this.details.subTitle,
            link: location.origin + location.pathname + '#/login?path=%2FcouponCenter&sharerId='+this.details.sharerId+'&rid='+this.details.rid+'&mid='+this.list2[0].mid,
            imgUrl: location.origin + location.pathname + 'img/logo_03.png'
        })
    },
    //邀请领券
    doShare () {
        doShare(this.$route.query).then(res=>{
            this.$refs.popup.show = true
        })
    }
  },
  created(){
      if (this.$route.query.sharerId) {
        this.doShare()
      }
      this.getCatalogs()
  },
  mounted(){},
  destroyed(){
        clearInterval(this.timer)
        var agent = navigator.userAgent.toLowerCase()
        if (/micromessenger/.test(agent)) {
        this.$ittShare({
            title: '厦门市民鹭岛生活·99节',
            desc: '惠民补贴、好物优选，抢先GO!9.9元专区~特惠来袭',
            link: location.origin + location.pathname + '#/home',
            imgUrl: location.origin + location.pathname + 'img/logo_03.png'
        })
        }
        
  }
}
</script>
<style scoped>
.wrapper{
    width: 100%;
    min-height: 100vh;
    background-image: url(/img/couponCenter-bg.png);
    background-size: .64rem .64rem;
}
.wrapper .banner{
    width: 100vw;
    height: 3rem;
}

.wrapper .time-box{
    width: 100%;
    height: .8rem;
    background-image: url(/img/time-bg.png);
    background-size: 100% 100%;
}
.wrapper .time-box .txt{
    font-size: .34rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
    margin: 0 .1rem;
}
.wrapper .time-box .colon{
    font-size: .37rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
}
.wrapper .time-box .time{
    width: .44rem;
    height: .44rem;
    background: #000000;
    border-radius: .04rem;
    font-size: .29rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: .44rem;
    margin: 0 .1rem;
}
.title-img{
    width: 100%;
    height: 1rem;
    
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    margin-top: .4rem;
}
.list-box1{
    width: 7.24rem;
    min-height: 5.52rem;
    padding: 0rem 0 .2rem;
    background-color: RGBA(254, 158, 70, 1);
    border-radius: .2rem;
    /* background-image: url(/img/couponCenter-bg1.png);
    background-size: 100% 100%; */
    margin-top: .05rem;
    box-sizing: border-box;
}
.list-box1 .list{
    width: 100%;
    margin-top: .12rem;
    margin-bottom: .12rem;
}
.list-box1 .list .item{
    position: relative;
    width: 6.86rem;
    height: 1.57rem;
    background-image: url(/img/couponCenter-coupon1.png);
    background-size: 100% 100%;
    margin-top: .12rem;
}
.list-box1 .list .item .goodsPicture{
    width: 1.33rem;
    height: 1.33rem;
    margin-left: .22rem;
    object-fit: cover;
}
.list-box1 .list .item-r{
   
    height: 100%;
    margin-left: .5rem;
}
.list-box1 .list .item-r .name{
    width: 3.3rem;
    font-size: .21rem;
    font-family: PingFang;
    font-weight: bold;
    color: #D62424;
    margin-top: .2rem;
}
.list-box1 .list .item-r .i{
    width: 1.21rem;
    height: .26rem;
    background: linear-gradient(90deg, #FB1D1A 0%, #972ED4 100%);
    border-radius: .13rem 0px .13rem 0px;
    font-size: .21rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FEFDFC;
    text-align: center;
    line-height: .26rem;
    margin-right: .1rem;
    margin-top: .2rem;
}
.list-box1 .list .item-r .money{
    font-size: .62rem;
    font-family: PingFang;
    font-weight: bold;
    color: #EF2121;
    line-height: .62rem;
    margin-top: .05rem;
}
.list-box1 .list .item-r .money::before{
    content: '¥';
    font-size: .3rem;
    font-family: PingFang;
    font-weight: bold;
    color: #EF2121;
    line-height: .3rem;
}
.list-box1 .list .item-r .time{
    font-size: .2rem;
    font-family: PingFang;
    font-weight: 500;
    color: #D62424;
    line-height: .2rem;
    margin-top: .05rem;
}
.list-box1 .list .item .btn1{
    position: absolute;
    right: .28rem;
    width: 1.4rem;
    height: .42rem;
    background: linear-gradient(90deg, #F1E4D1 0%, #FFFFFF 100%);
    box-shadow: 0px .02rem .08rem 0px rgba(171, 91, 34, 0.39);
    border-radius: .2rem;
    font-size: .21rem;
    font-family: PingFang;
    font-weight: bold;
    color: #D62424;
    line-height: .42rem;
}
.list-box1 .list .item .btn1 .icon{
    width: 0;
    height: 0;
    border-left: .07rem solid rgba(214, 36, 36, 1);
    border-top: .04rem solid transparent;
    border-bottom: .04rem solid transparent;
    border-right: 0rem solid transparent;
    margin-left: .07rem;
}
.list-box1 .list .item .btn2{
    position: absolute;
    right: .28rem;
    width: 1.4rem;
    height: .42rem;
    background: linear-gradient(90deg, #F51A1A 0%, #FE5D70 100%);
    box-shadow: 0px .02rem .08rem 0px rgba(171, 91, 34, 0.39);
    border-radius: .2rem;
    font-size: .21rem;
    font-family: PingFang;
    font-weight: bold;
    color: #fff;
    line-height: .42rem;
}
.list-box1 .list .item .btn2 .icon{
    width: 0;
    height: 0;
    border-left: .07rem solid #fff;
    border-top: .04rem solid transparent;
    border-bottom: .04rem solid transparent;
    border-right: 0rem solid transparent;
    margin-left: .07rem;
}
.list-box1 .list .item .btn3{
    position: absolute;
    right: .28rem;
    width: 1.4rem;
    height: .42rem;
    background: linear-gradient(90deg, #B0A1A1 0%, #DACACA 100%);
    box-shadow: 0px .02rem .08rem 0px rgba(171, 91, 34, 0.39);
    border-radius: .2rem;
    font-size: .21rem;
    font-family: PingFang;
    font-weight: bold;
    color: rgba(114, 76, 76, 1);
    line-height: .42rem;
}
.list-box1 .list .item .btn3 .icon{
    width: 0;
    height: 0;
    border-left: .07rem solid rgba(114, 76, 76, 1);
    border-top: .04rem solid transparent;
    border-bottom: .04rem solid transparent;
    border-right: 0rem solid transparent;
    margin-left: .07rem;
}
.list-box2{
    position: relative;
    width: 7.5rem;
    height: 6.96rem;
    background-image: url(/img/couponCenter-bg2.png);
    background-size: 100% 100%;
    margin-top: .05rem;
    box-sizing: border-box;
}
.my-swipe{
    width: 6.7rem;
    height: 84%;
    margin-left: .05rem;
    margin-top: 1.05rem;
}
.list-box2 .list{
    width: 6.7rem;
    height: 3rem;
}
.list-box2 .list .item{
    width: 2.04rem;
    height: 2.53rem;
    background: #FFFFFF;
    box-shadow: 0px .04rem .04rem 0px rgba(209, 95, 33, 0.33);
    border-radius: .12rem;
    margin: .08rem .09rem;
}
.list-box2 .list .item .goodsPicture{
    width: 1.3rem;
    height: 1.3rem;
    object-fit: cover;
    margin-top: .1rem;
}
.list-box2 .list .item .coupon{
    width: 1.65rem;
    height: .96rem;
    padding-left: .2rem;
    box-sizing: border-box;
    background-image: url(/img/couponCenter-coupon2.png);
    background-size: 100% 100%;
    margin-top: .1rem;
}
.list-box2 .list .item .coupon .t1{
    font-size: .41rem;
    font-family: PingFang;
    font-weight: bold;
    color: #FFFFFF;
    line-height: .41rem;
}
.list-box2 .list .item .coupon .t2{
    font-size: .21rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    line-height: .21rem;
    margin-top: .1rem;
}
.timer{
    width: 5.55rem;
    height: .37rem;
    background: rgba(121, 33, 32, 0.35);
    border-radius: .1rem;
    margin-right: .7rem;
    margin-top: .2rem;
    font-size: .26rem;
    font-family: PingFang;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    line-height: .37rem;
}
.list-box2 .time .font{
    width: 1.7rem;
}
.invitation-btn{
    width: 7.25rem;
    height: .97rem;
    background-image: url(/img/invitation-btn.png);
    background-size: 100% 100%;
    margin-top: .15rem;
}
.poster-btn{
    width: 7.25rem;
    height: .97rem;
    background-image: url(/img/poster-btn.png);
    background-size: 100% 100%;
    margin-top: .15rem;
}
.list-box3{
    width: 7.24rem;
    min-height: 6rem;
    padding: 0rem 0 .2rem;
    background-color: RGBA(254, 158, 70, 1);
    border-radius: .2rem;
    /* background-image: url(/img/couponCenter-bg1.png);
    background-size: 100% 100%; */
    margin-top: .05rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
}
.list-box3 .list{
    margin-top: .1rem;
}
.list-box3 .list .item{
    position: relative;
    width: 6.88rem;
    height: 1.7rem;
    background-image: url(/img/couponCenter-coupon3.png);
    background-size: 100% 100%;
    margin-top: .18rem;
    overflow: hidden;
    border-radius: .16rem;
}
.list-box3 .list .item .goodsPicture{
    width: 1.7rem;
    height: 1.7rem;
    object-fit: cover;
    margin-right: .14rem;
}
.list-box3 .list .item .item-r .name{
    width: 3.2rem;
    font-size: .32rem;
    font-family: PingFang;
    font-weight: bold;
    color: #282828;
    line-height: .32rem;
}
.list-box3 .list .item .item-r .money{
    font-size: .26rem;
    font-family: PingFang;
    font-weight: bold;
    color: #FF0A02;
    line-height: .26rem;
    margin-top: .2rem;
}
.list-box3 .list .item .item-r .money font{
    font-size: .54rem;
    line-height: .54rem;
}
.list-box3 .list .item .btn1{
    position: relative;
    z-index: 2;
    width: 1.46rem;
    height: .46rem;
    text-align: center;
    line-height: .46rem;
    background: linear-gradient(0deg, #E6110F 0%, #FF5B5A 100%);
    border-radius: .23rem;
    font-size: .3rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    margin-left: .2rem;
}
.list-box3 .list .item .btn2{
    position: relative;
    z-index: 2;
    width: 1.46rem;
    height: .46rem;
    text-align: center;
    line-height: .46rem;
    border-radius: .23rem;
    font-size: .3rem;
    font-family: PingFang;
    font-weight: 500;
    color: rgba(235, 31, 29, 1);
    margin-left: .2rem;
    box-sizing: border-box;
    border: .02rem solid #EB1F1D;
}
.list-box3 .list .item .btn3{
    position: relative;
    z-index: 2;
    width: 1.46rem;
    height: .46rem;
    text-align: center;
    line-height: .46rem;
    background: rgba(188, 173, 173, 1);
    border-radius: .23rem;
    font-size: .3rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FDF8F4;
    margin-left: .2rem;
}
.list-box3 .list .item .received{
    position: absolute;
    right: -.1rem;
    bottom: -.3rem;
    width: 1.05rem;
    height: 1.05rem;
}
</style>