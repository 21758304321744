<template>
  <van-popup v-model="show" 
  closeable
  close-icon="close">
      <div class="box flex fd-c ai-c">
          <div class="t1">亲友共享券</div>
          <div class="t2">分享好友共享千元市民补贴</div>
          <div class="list flex fd-c ai-c">
              <div class="item flex ai-c" v-for="(item, index) in list" :key="index">
                  <div class="item-l flex fd-c ai-c jc-c">
                      <img :src="item.couponCover" alt="">
                  </div>
                  <div class="item-r">
                      <div class="name line">{{item.name}}{{item.name}}</div>
                      <div class="flex ai-bl">
                          <div class="lb">￥</div><div class="money">{{item.reduceAmount}}</div><div class="tt">元商品优惠券</div>
                      </div>
                  </div>
                  <div class="btn" @click="Click(item)">去使用</div>
              </div>
          </div>
          <div class="txt" v-if="list.length>0">{{list.length}}张优惠券已到账-到个人中心查看</div>
      </div>
      
  </van-popup>
</template>

<script>
export default {
  components:{},
  props:['list'],
  data(){
    return {
        show: false
    }
  },
  watch:{
      
  },
  computed:{
      
  },
  methods:{
      Click() {
          this.$router.push('/my/coupons')
      }
  },
  created(){
      
  },
  mounted(){}
}
</script>
<style scoped>
.van-popup{
    background-color: transparent;
}
.van-popup>>>.van-icon{
    top: 0;
    right: 0;
    font-size: .5rem;
}
.box{
    width: 5.93rem;
    height: 8.01rem;
    background-size: 100% 100%;
    background-image: url(/img/newRedBg.png);
}
.box .t1{
    font-size: .48rem;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #EE2C26;
    margin-top: .35rem;
    line-height: .32rem;
}
.box .t2{
    font-size: .28rem;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #EE2C26;
    margin-top: .14rem;
}
.list {
    width: 100%;
    height: 5rem;
    margin-top: 1.1rem;
    overflow-y: scroll;
}
.list .item{
    position: relative;
    width: 5.28rem;
    height: 1.14rem;
    min-height: 1.14rem;
    background: #FFF9ED;
    border-radius: .25rem;
    overflow: hidden;
    margin-bottom: .1rem;
}
.list .item .item-l {
    width: 1.04rem;
    height: 1.04rem;
    background-color: #fff;
}
.list .item .item-l img{
    width: 100%;
    height: 100%;
}
.list .item .item-r{
    margin-left: .2rem;
}
.list .item .item-r .name{
    width: 2.7rem;
    font-size: .24rem;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: .26rem;
}

.list .item .item-r .lb{
    font-size: .24rem;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #F14439;
}
.list .item .item-r .money{
    font-size: .48rem;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #F14439;
    line-height: .52rem;
}
.list .item .item-r .tt{
    font-size: .18rem;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #F14439;
}
.list .item .btn{
    position: absolute;
    right: .16rem;
    top: .2rem;
    width: 1.10rem;
    height: .28rem;
    line-height: .28rem;
    text-align: center;
    font-size: .22rem;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #FFF9ED;
    border-radius: .12rem;
    background: #F14439;
}
.txt{
    margin-top: .1rem;
    font-size: .24rem;
    color: #fff;
}
.box1{
    width: 5.93rem;
    height: 8.01rem;
    background-size: 100% 100%;
    background-image: url(/img/kyqRed.png);
}
.ClickBox{
    width: 5.3rem;
    height: 4.9rem;
    margin-top: 2.4rem;
}
</style>